import React from 'react';
import { View } from 'react-native';
import { Heading } from '../shared/components/heading';
import { Paragraph } from '../shared/components/paragraph';
import { AnonymousScreen } from '../shared/screens/anonymousScreen';

const RequestAccountDeletion = () => (
  <AnonymousScreen>
    <Heading>Request Account Deletion</Heading>
    <p>
      To request the deletion of your <strong>BreathingRoom</strong> account,
      you can either:
      <p>Go to the Profile screen and click "Request Account Deletion"</p>
      OR
      <p>
        Send an email to info@cinim.org with the subject line "Request for
        Account Deletion". You will need to provide information to our customer
        support to verify that you are the owner of the account you are
        requesting data for, or have the legal authority to do so.
      </p>
    </p>
    <p>
      Upon successful verification and account deletion we will provide you with
      an email confirmation, to the email associated with your request, of your
      account deletion and the information that was deleted.
    </p>
    <p>
      Please note that in order to comply with our compliance and regulatory
      obligations, we may not be able to provide or permanently delete certain
      data in accordance with our legal and record keeping obligations. Please
      see our Terms and Conditions, and Privacy Policy for more information.
    </p>
  </AnonymousScreen>
);

export { RequestAccountDeletion };
