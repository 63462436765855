import React from 'react';
import { View, StyleSheet } from 'react-native';

import { ActionImage } from './actionImage';
import { Heading, HeadingType } from './heading';
import { useLocale } from '../hooks/useLocale';
import { useStaticTranslation } from '../services/staticTranslations';
import { Languages, InAppPurchases } from '../util/enums';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../util/links';

const appStoreBadge = locale => {
  switch (locale) {
    case Languages.french:
      return require('../../img/app-store-badge-fr.svg').default;
    case Languages.spanish:
      return require('../../img/app-store-badge-es.svg').default;
    default:
      return require('../../img/app-store-badge.svg').default;
  }
};

const googlePlayBadge = locale => {
  switch (locale) {
    case Languages.french:
      return require('../../img/google-play-badge-fr.jpg');
    case Languages.spanish:
      return require('../../img/google-play-badge-es.jpg');
    default:
      return require('../../img/google-play-badge.jpg');
  }
};

const InAppPurchase = function (props) {
  const { disabled, type } = props;
  const msg =
    type === InAppPurchases.GIFT
      ? 'Download the app to buy a digital gift card with an in-app purchase:'
      : 'Download the app to get full access with an in-app purchase:';
  const locale = useLocale();

  return (
    <View style={styles.iapView}>
      <Heading type={HeadingType.FOUR}>{useStaticTranslation(msg)}</Heading>
      <View style={styles.iapBadges}>
        <ActionImage
          style={styles.iapAppStoreBadge}
          disabled={disabled}
          openUrl={APP_STORE_URL}
          imageSource={appStoreBadge(locale)}
        />
        <ActionImage
          style={styles.iapPlayBadge}
          disabled={disabled}
          openUrl={GOOGLE_PLAY_URL}
          imageSource={googlePlayBadge(locale)}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  iapView: {},
  iapBadges: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  iapAppStoreBadge: {
    width: 42 * 3,
    height: 42,
    margin: 10,
  },
  iapPlayBadge: {
    width: 161,
    height: 62,
  },
});

export { InAppPurchase };
