import React from 'react';
import { StyleSheet } from 'react-native';

const OnboardingButtonImage = function ({ style }) {
  const oneX = require('./onboarding-button.jpg');
  const twoX = require('./onboarding-button@2x.jpg');
  return <img src={twoX} alt="" style={StyleSheet.flatten(style)} srcSet={`${oneX}, ${twoX} 2x`} />;
};

export { OnboardingButtonImage };
