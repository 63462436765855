import React from 'react';
import { StyleSheet } from 'react-native';

const ResourceAudioImage = function ({ style }) {
  const oneX = require('./resource-audio.jpg');
  const twoX = require('./resource-audio@2x.jpg');
  return (
    <img
      src={twoX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x`}
    />
  );
};

export { ResourceAudioImage };
