import React from 'react';

import { OnboardingLargeImage } from './OnboardingLargeImage';

const OnboardingAssessmentsLargeImage = function ({ style }) {
  const twoX = {
    es: require('./es/onboarding-assessments@2x.jpg'),
    fr: require('./fr/onboarding-assessments@2x.jpg'),
    en: require('./onboarding-assessments@2x.jpg'),
  };
  const threeX = {
    es: require('./es/onboarding-assessments@3x.jpg'),
    fr: require('./fr/onboarding-assessments@3x.jpg'),
    en: require('./onboarding-assessments@3x.jpg'),
  };
  return <OnboardingLargeImage oneX={twoX} twoX={twoX} threeX={threeX} style={style} />;
};

export { OnboardingAssessmentsLargeImage };
