import React from 'react';
import { TouchableHighlight, Platform, StyleSheet } from 'react-native';

import { TopicHero } from './topicHero';
import { AppStyles } from '../services/styles';
import { pressPropsForTouchable } from '../util/utils';

const TopicHeroButton = function (props) {
  const action = props.action;
  const propStyle = props.style || {};
  const style = [styles.thbView, propStyle];

  return (
    <TouchableHighlight
      {...pressPropsForTouchable(action)}
      style={style}
      role="button"
      accessibilityRole="button"
      aria-label={props.title}>
      <TopicHero {...props} />
    </TouchableHighlight>
  );
};

const styles = StyleSheet.create({
  thbView: {
    overflow: 'hidden',
    ...(Platform.OS === 'web'
      ? {
          transform: 'translateZ(0)', //fix bug in mobile safari caused by having an overflow:hidden element and/or border radius inside of overflow auto
        }
      : {}),
    borderRadius: AppStyles.normalBorderRadius,
    width: '100%',
    position: 'relative',
    paddingTop: '66.66%',
  },
});

export { TopicHeroButton };
