import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { NotFoundScreen } from '../screens/notFoundScreen';
import { ResetPasswordScreen } from '../screens/resetPasswordScreen';
import { UnverifiedScreen } from '../screens/unverifiedScreen';
import { useIsAuthenticatedLocaleReady } from '../shared/hooks/useDynamicTranslation';
import { AnonymousInterface } from '../shared/screens/anonymousInterface';
import { DashboardScreen } from '../shared/screens/dashboardScreen';
import { DisabledScreen } from '../shared/screens/disabledScreen';
import { ForgotPasswordScreen } from '../shared/screens/forgotPasswordScreen';
import { InterfaceLibraryScreen } from '../shared/screens/interfaceLibraryScreen';
import { LibraryScreen } from '../shared/screens/libraryScreen';
import { LoginScreen } from '../shared/screens/loginScreen';
import { LogoutScreen } from '../shared/screens/logoutScreen';
import { ProfileScreen } from '../shared/screens/profileScreen';
import { ProgressScreen } from '../shared/screens/progressScreen';
import { RegisterScreen } from '../shared/screens/registerScreen';
import { ResourcesScreen } from '../shared/screens/resourcesScreen';
import { TopicDetailScreen } from '../shared/screens/topicDetailScreen';
import { TopicOverviewScreen } from '../shared/screens/topicOverviewScreen';
import {
  AUTH_STATUS_AUTHENTICATED,
  USER_FIELD_AUTH_STATUS,
  USER_FIELD_EMAIL_IS_VERIFIED,
  USER_FIELD_IS_DISABLED,
  useUserInfo
} from '../shared/services/apiService';
import { USER_FIELD_TOC_AGREED } from '../shared/services/apiService';
import { env } from '../shared/util/env';

const WrappedScreen = ({
  Screen,
  isAnonymousScreen = true,
  passProps = {}
}) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const passParams = {};
  const queryParams = new URLSearchParams(location.search);
  queryParams.forEach((value, key) => {
    passParams[key] = value;
  });
  const navigateTo = (path, replace = false) => {
    if (replace) {
      navigate(path, { replace: true });
    } else {
      navigate(path);
    }
  };
  if (isAnonymousScreen) {
    return (
      <AnonymousInterface
        screen={Screen}
        forwardProps={{
          ...params,
          ...passParams,
          navigateTo,
          ...passProps
        }}
      />
    );
  }
  return (
    <Screen
      {...params}
      {...passParams}
      navigateTo={navigateTo}
      {...passProps}
    />
  );
};

const AppRoutes = (props) => {
  const { loadingView, errorView } = props;
  const [isAuthLocaleReady, authLocaleError] = useIsAuthenticatedLocaleReady();
  const authStatus = useUserInfo(USER_FIELD_AUTH_STATUS);
  const emailIsVerified = useUserInfo(USER_FIELD_EMAIL_IS_VERIFIED);
  const isDisabled = useUserInfo(USER_FIELD_IS_DISABLED);
  const agreedToConditions = useUserInfo(USER_FIELD_TOC_AGREED);
  const _userMustAgreeToTerms =
    agreedToConditions === null ? false : !agreedToConditions; //null means 'unknown', so ignore null

  const isAnonymousUser = authStatus !== AUTH_STATUS_AUTHENTICATED;
  const isDisabledUser = authStatus === AUTH_STATUS_AUTHENTICATED && isDisabled;
  const isUnverifiedUser =
    authStatus === AUTH_STATUS_AUTHENTICATED && !emailIsVerified;

  function secureRouteComponent(screen) {
    if (isAnonymousUser) {
      return <WrappedScreen Screen={LoginScreen} />;
    }

    if (isDisabledUser) {
      return <Navigate to="/disabled" />;
    }

    if (isAuthLocaleReady) {
      return <WrappedScreen Screen={screen} isAnonymousScreen={false} />;
    }

    if (authLocaleError) {
      return errorView;
    }

    // If none of the above conditions are met, return loadingView
    return loadingView;
  }

  return (
    <BrowserRouter>
      <Routes>
        {/*Public and private route*/}
        {env.environment === 'local' ? (
          <Route
            path="/ui"
            element={
              <WrappedScreen
                Screen={InterfaceLibraryScreen}
                isAnonymousScreen={false}
              />
            }
          />
        ) : null}

        <Route
          path="/logout"
          element={<WrappedScreen Screen={LogoutScreen} />}
        />

        <Route
          path="/password/reset/:token"
          element={
            isDisabledUser ? (
              <Navigate to="/disabled" />
            ) : (
              <WrappedScreen Screen={ResetPasswordScreen} />
            )
          }
        />

        {/*Public routes*/}
        <Route
          path="/password/reset"
          element={
            isAnonymousUser ? (
              <WrappedScreen Screen={ForgotPasswordScreen} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/login"
          element={
            isAnonymousUser ? (
              <WrappedScreen Screen={LoginScreen} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/register"
          element={
            isAnonymousUser ? (
              <WrappedScreen Screen={RegisterScreen} />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/*Disabled route*/}
        <Route
          path="/disabled"
          element={
            isDisabledUser ? (
              <WrappedScreen Screen={DisabledScreen} />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/*Unverified route*/}
        <Route
          path="/email/verify/:verificationId"
          element={
            isDisabledUser ? (
              <Navigate to="/disabled" />
            ) : (
              <WrappedScreen Screen={UnverifiedScreen} />
            )
          }
        />

        {/*TODO: maybe get rid of this route/screen altogether*/}
        <Route
          path="/email/verify"
          element={
            isAnonymousUser ? (
              <WrappedScreen Screen={LoginScreen} />
            ) : isDisabledUser ? (
              <Navigate to="/disabled" />
            ) : isUnverifiedUser ? (
              <WrappedScreen Screen={UnverifiedScreen} />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/*Secure routes*/}
        <Route path="/users/me" element={secureRouteComponent(ProfileScreen)} />
        <Route
          path="/topic_overview/:topicId"
          element={secureRouteComponent(TopicOverviewScreen)}
        />
        <Route
          path="/topic_detail/:topicId"
          element={secureRouteComponent(TopicDetailScreen)}
        />
        <Route path="/topics" element={secureRouteComponent(LibraryScreen)} />
        <Route
          path="/progress"
          element={secureRouteComponent(ProgressScreen)}
        />
        <Route
          path="/resources/:resourceId"
          element={secureRouteComponent(ResourcesScreen)}
        />
        <Route
          path="/resources"
          element={secureRouteComponent(ResourcesScreen)}
        />
        <Route
          path="/dashboard"
          element={secureRouteComponent(DashboardScreen)}
        />

        {/*Public and secure routes*/}
        <Route exact path="/" element={secureRouteComponent(DashboardScreen)} />

        {/*404*/}
        <Route path="*" element={<WrappedScreen Screen={NotFoundScreen} />} />
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes as Routes };
