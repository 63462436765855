import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { StyleSheet, ScrollView, Platform, View, TouchableOpacity, Animated } from 'react-native';

import { AuthenticatedScreen, MAX_AUTH_SCREEN_CONTENT_WIDTH } from './authenticatedScreen';
import { ActionButton, ActionButtonType } from '../components/actionButton';
import { ActionLink } from '../components/actionLink';
import { CompletedBadge, CompletedBadgeSizes } from '../components/completedBadge';
import { CONTENT_BOX_ROW_MIN_HEIGHT, ContentBoxRow } from '../components/contentBoxRow';
import { CornerButton } from '../components/cornerButton';
import { DraftBadge } from '../components/draftBadge';
import { Heading, HeadingType } from '../components/heading';
import { Icon } from '../components/icon';
import { MajorError } from '../components/majorError';
import { Paragraph, ParagraphSizes } from '../components/paragraph';
import { SubscribePopup } from '../components/popups/SubscribePopup';
import { ActivityPopup } from '../components/popups/activityPopup';
import { AlertPopup } from '../components/popups/alertPopup';
import { AssessmentPopup } from '../components/popups/assessmentPopup';
import { ResourcePopup } from '../components/popups/resourcePopup';
import { ProgressBar, ProgressBarType } from '../components/progressBar';
import { ResourcesList } from '../components/resourcesList';
import { ScreenLoadingIndicator } from '../components/screenLoadingIndicator';
import { TopicBanner } from '../components/topicBanner';
import { VimeoVideo } from '../components/vimeoVideo';
import { useAuthContentStyles } from '../hooks/useAuthContentStyles';
import { TranslatableResourceTypes, useDynamicTranslation } from '../hooks/useDynamicTranslation';
import { useElementDimensions } from '../hooks/useElementDimensions';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { ScreenSizes, useScreenSize, useStylesForWidth } from '../hooks/useStyleForWidth';
import {
  ApiService,
  useApiRequest,
  USER_FIELD_IS_TRIAL,
  useUserInfo,
} from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, AppStyles, IconNames, IconSizes } from '../services/styles';
import {
  ActivityType,
  AnalyticsScreenNames,
  Breakpoints,
  MainSections,
  PublishStatus,
} from '../util/enums';
import {
  getShadowStyles,
  iconForActivity,
  isItemComplete,
  percentCompletionForTopic,
  TIME_TO_COMPLETE_ASSESSMENT,
} from '../util/utils';

function useItemMenuTitle(item) {
  item = item || {
    //item could be null
    id: 0,
    title: '',
  };
  const title = useDynamicTranslation(
    item.id,
    item.activity_type ? TranslatableResourceTypes.ACTIVITY : TranslatableResourceTypes.STEP,
    'title',
    item.title
  );
  const prefix =
    (item.activity_type || -1) === ActivityType.JOURNALING ? staticTranslation('Journal: ') : '';
  return prefix + title;
}

function getNextAfterCurrentStep(currentStep, data) {
  const segments = data.module.segments;
  let found = false;
  for (let i = 0; i < segments.length; i++) {
    const items = getSortedItemsInSegment(segments[i], data.module.activities);
    for (let j = 0; j < items.length; j++) {
      if (
        items[j].id === currentStep.id && //steps and activities can have the same ID!
        items[j].title === currentStep.title
      ) {
        //so we check the titles also
        found = true;
      } else if (found) {
        return items[j];
      }
    }
  }
  if (found) {
    return 'assessment';
  }
  return null;
}

function getSortedItemsInSegment(segment, activities) {
  const activitiesInSegment = activities.filter(a => a.segment_id === segment.id);
  return [...segment.steps, ...activitiesInSegment].sort((a, b) => {
    if (a.sort_order > b.sort_order) return 1;
    if (a.sort_order < b.sort_order) return -1;
    return 0;
  });
}

function extractCurrentModels(data, stepId, activityId, resourceId, isTrialUser) {
  let segment = null;
  let step = null;
  let invalidActivityId = false;
  let activity = null;
  let resource = null;
  let invalidResourceId = null;
  let defaultSegment = null;
  const topic = data.module;
  const segments = topic.segments;
  const isAccessibleItem = item => !isTrialUser || item.is_trial_content;
  for (let i = 0; i < segments.length && !segment; i++) {
    if (!defaultSegment && segments[i].steps.filter(isAccessibleItem).length) {
      defaultSegment = segments[i]; //in case no valid step is specified, default to the first segment with an accessible step
    }
    const match = segments[i].steps.filter(
      o => ((!stepId && !isItemComplete(o)) || o.id === stepId) && isAccessibleItem(o)
    ); //when finding the proper step to show, make sure it is accessible
    if (match.length) {
      step = match[0];
      segment = segments[i];
    }
  }
  let invalidStepId = !stepId; //force a stepId param
  if (!step) {
    //should only get here if stepId was specified but is invalid OR all steps are complete OR the stepID specified is inaccessible due to trial mode
    segment = defaultSegment || segments[0];
    const accessibleSteps = segment ? segment.steps.filter(isAccessibleItem) : [];
    step = accessibleSteps.length > 0 ? accessibleSteps[0] : null; //if step is null the whole page breaks, but this is only possible if a topic has NO steps
    if (stepId) {
      //if we had to find a default step, then the one specified in the URL is invalid or off limits
      invalidStepId = true;
    }
  }
  if (activityId) {
    const activities = topic.activities;
    const match = activities.filter(o => o.id === activityId);
    if (match.length) {
      activity = match[0];
    } else {
      invalidActivityId = true;
    }
  }
  if (resourceId) {
    const resources = topic.resources;
    const match = resources.filter(o => o.id === resourceId);
    if (match.length) {
      resource = match[0];
    } else {
      invalidResourceId = true;
    }
  }
  return {
    segment,
    step,
    invalidStepId,
    activity,
    invalidActivityId,
    topic,
    resource,
    invalidResourceId,
  };
}

const NextStepOverlay = function (props) {
  const { nextStep, navigateWithinTopic } = props;
  const title = useItemMenuTitle(
    nextStep === 'assessment'
      ? {
          id: 0,
          title: staticTranslation('Assessment'),
        }
      : nextStep
  );
  return (
    <View style={styles.tdNextStepView}>
      <Heading type={HeadingType.THREE} style={styles.tdNextStepText}>
        {staticTranslation('Next up:')}
      </Heading>
      <TouchableOpacity
        style={styles.tdNextStepButton}
        onPress={() => {
          if (nextStep === 'assessment') {
            navigateWithinTopic({ assessment: true });
          } else if (nextStep.activity_type) {
            navigateWithinTopic({ activity: nextStep });
          } else {
            navigateWithinTopic({ step: nextStep, autoplay: true });
          }
        }}>
        <Icon name={IconNames.play} size={IconSizes.xlarge} color={AppColors.white} />
      </TouchableOpacity>
      <Paragraph style={styles.tdNextStepText}>{title}</Paragraph>
    </View>
  );
};

const TopicMenuItem = function (props) {
  const { item, title, minutes, isDraft, isActive, isComplete, action, icon } = props;
  const itemTitle = useItemMenuTitle(item); //item is optional so this might return null

  const iconImage = isComplete ? null : (
    <Icon color={AppColors.white} name={icon} size={IconSizes.small} />
  );
  const badge = isComplete ? (
    <CompletedBadge size={CompletedBadgeSizes.SMALL} />
  ) : (
    <View style={[styles.tdMenuBadge, isActive ? styles.tdMenuBadgeActive : {}]}>{iconImage}</View>
  );

  const left = (
    <View style={styles.tdMenuItem}>
      {badge}
      <Paragraph
        size={ParagraphSizes.SMALL}
        bold
        style={[styles.tdMenuItemTitle, isActive ? styles.tdMenuItemTitleActive : {}]}>
        {itemTitle || title}
        {isDraft ? <DraftBadge /> : null}
      </Paragraph>
    </View>
  );

  const right = (
    <Paragraph
      size={ParagraphSizes.SMALL}
      bold
      style={isActive ? styles.tdMenuItemNP : styles.tdMenuItemTime}>
      {staticTranslation(isActive ? 'NOW VIEWING' : ':number min', {
        number: minutes,
      })}
    </Paragraph>
  );

  return <ContentBoxRow action={action} left={left} right={right} />;
};

const SegmentHeader = function (props) {
  const { segment } = props;
  const title = useDynamicTranslation(
    segment.id,
    TranslatableResourceTypes.SEGMENT,
    'title',
    segment.title
  );
  return (
    <Heading type={HeadingType.FOUR} style={styles.tdMenuSegmentTitle}>
      {title}
    </Heading>
  );
};

const TopicDetailScreen = function (props) {
  useScreenView(AnalyticsScreenNames.topicDetail, 'TopicDetailScreen');
  const topicId = props.topicId;
  const doingAssessment = props.assessment || false;
  const [currentTopic, setCurrentTopic] = useState(null);
  const [currentSegment, setCurrentSegment] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [currentResource, setCurrentResource] = useState(null);
  const topicRequest = useApiRequest(
    cb => {
      return ApiService.getModule(topicId, true, cb);
    },
    [topicId],
    true
  );
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  const showSubscriptionPopup = props.subscribe;

  const nt = props.navigateTo;
  const navigateWithinTopic = useCallback(
    options => {
      setShowingNextStep(false);
      setMenuVisibleOnSM(false);
      const { step, activity, resource, assessment, autoplay, replaceHistory } = options;
      const item = step || activity || resource;
      const isLocked = isTrialUser && (assessment || (item && !item.is_trial_content));
      const navigateToStep = isLocked ? currentStep : step || currentStep;
      const params = navigateToStep ? ['stepId=' + navigateToStep.id] : [];
      if (isLocked) {
        params.push('subscribe=1');
      } else if (assessment) {
        params.push('assessment=true');
      } else if (resource) {
        params.push('resourceId=' + resource.id);
      } else if (activity) {
        params.push('activityId=' + activity.id);
      } else if (autoplay) {
        params.push('autoplay=true');
      }
      nt(
        '/topic_detail/' + topicId + (params.length ? '?' + params.join('&') : ''),
        replaceHistory
      );
      //TODO: on react native, call AuthScreen.scrollToTop() ?
      if (step && Platform.OS === 'web') {
        window.scrollTo(0, 0);
      }
    },
    [isTrialUser, currentStep, nt, topicId]
  );

  const topicTitle = useDynamicTranslation(
    (currentTopic || {}).id || '0',
    TranslatableResourceTypes.TOPIC,
    'title',
    (currentTopic || {}).title || ''
  );

  useScreenTitle(topicTitle, false);

  useEffect(() => {
    if (topicRequest.succeeded) {
      const models = extractCurrentModels(
        topicRequest.data,
        parseInt(props.stepId, 10) || null,
        parseInt(props.activityId, 10) || null,
        parseInt(props.resourceId, 10) || null,
        isTrialUser
      );
      setCurrentStep(models.step);
      setCurrentSegment(models.segment);
      setCurrentActivity(models.activity);
      setCurrentTopic(models.topic);
      setCurrentResource(models.resource);
      if (models.invalidStepId || models.invalidActivityId || models.invalidResourceId) {
        navigateWithinTopic({ step: models.step, replaceHistory: true });
      }
    }
  }, [topicRequest, props, topicId, navigateWithinTopic, isTrialUser]);

  const marginedContentStyle = useStylesForWidth(styles, 'tdMarginedContent');
  const renderError = () => {
    return (
      <MajorError
        boxed
        style={marginedContentStyle}
        message={staticTranslation(
          'There was a problem loading the details for this topic. Please check your network connection and try again.'
        )}
        error={topicRequest.error}
        actionTitle={staticTranslation('Try Again')}
        action={() => {
          topicRequest.reset();
          topicRequest.send();
        }}
      />
    );
  };

  const smMenuToggleLink = expand => {
    return (
      <TouchableOpacity
        style={[styles.tdSMMenuToggle, expand ? {} : styles.tdSMMenuToggleExpanded]}
        onPress={() => {
          if (expand) {
            scrollRef.current.scrollTo({
              x: 0,
              y: Math.max(currentStep.menuYPos - 100, 0),
              animated: false,
            });
          }
          setMenuVisibleOnSM(expand);
        }}>
        <View style={styles.tdSMMenuToggleTitleRow}>
          <Heading type={HeadingType.FOUR} style={styles.tdSMMenuToggleTitle}>
            {topicTitle}
          </Heading>
          <Icon
            name={expand ? IconNames.chevronUp : IconNames.chevronDown}
            size={IconSizes.normal}
            color={AppColors.darkText}
          />
        </View>
        <ProgressBar
          type={ProgressBarType.colored}
          style={styles.tdSMMenuToggleProgressBar}
          percent={percentCompletionForTopic(currentTopic, true)}
        />
      </TouchableOpacity>
    );
  };
  const [menuVisibleOnSM, setMenuVisibleOnSM] = useState(false);
  const smVideoNavStyle = useStylesForWidth(styles, 'tdNavigateContent');
  const renderSMVideoNav = () => {
    return <View style={smVideoNavStyle}>{smMenuToggleLink(true)}</View>;
  };
  const canRenderDetails = topicRequest.succeeded && currentSegment && currentStep;
  const mainViewRef = useRef(null);
  const mainViewWidth = useElementDimensions(mainViewRef, canRenderDetails).width;
  const screenSize = useScreenSize();
  const menuWidthStyle = useMemo(() => {
    if (screenSize < ScreenSizes.MD || mainViewWidth === 0) {
      return {};
    }
    const viewWidthInMDLGXL = Math.min(
      mainViewWidth * (VIEW_MDLGXL_WIDTH / 100),
      MAX_AUTH_SCREEN_CONTENT_WIDTH
    );
    return { width: mainViewWidth - viewWidthInMDLGXL };
  }, [mainViewWidth, screenSize]);
  const menuTopHidden = 100,
    menuTopVisible = 20;
  const menuBGOpacityHidden = 0,
    menuBGOpacityVisible = 0.5;
  // noinspection JSUnresolvedFunction
  const [menuTop] = useState(new Animated.Value(menuTopHidden));
  // noinspection JSUnresolvedFunction
  const [menuBGOpacity] = useState(new Animated.Value(menuBGOpacityHidden));
  // noinspection JSUnresolvedFunction
  const menuContentStyle = useStylesForWidth(styles, 'tdNavigateContent', menuWidthStyle);
  const menuInnerContentStyle = useStylesForWidth(styles, 'tdNavigateContentInner');
  if (screenSize === ScreenSizes.SM) {
    // noinspection JSUnresolvedFunction
    menuContentStyle.push({
      top: menuTop.interpolate({
        inputRange: [0, 100],
        outputRange: ['0%', '100%'],
      }),
    });
  }
  useEffect(() => {
    // noinspection JSDeprecatedSymbols
    const animateBackground = Animated.timing(menuBGOpacity, {
      toValue: menuVisibleOnSM ? menuBGOpacityVisible : menuBGOpacityHidden,
      useNativeDriver: Platform.OS !== 'web',
    });
    // noinspection JSUnresolvedFunction
    const animateTop = Animated.spring(menuTop, {
      toValue: menuVisibleOnSM ? menuTopVisible : menuTopHidden,
      useNativeDriver: false,
    });

    Animated.parallel([animateTop, animateBackground], {
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }, [menuVisibleOnSM, menuTop, menuBGOpacity]);

  const viewStyle = useStylesForWidth(styles, 'tdView');
  const mainContentStyle = useStylesForWidth(styles, 'tdMainContent');
  const vimeoStyle = useStylesForWidth(styles, 'tdVimeo');
  // noinspection JSUnresolvedFunction
  const navBarStyle = useStylesForWidth(styles, 'tdNavigationBar');
  const progressRequest = useApiRequest(
    cb => {
      if (currentStep) {
        return ApiService.updateStepProgress(
          currentStep.id,
          currentStep.user_percent_complete,
          currentStep.user_time,
          cb
        );
      }
      return () => {};
    },
    [currentStep]
  );

  useEffect(() => {
    if (progressRequest.error) {
      console.error('Error saving step progress:', progressRequest.error);
      progressRequest.reset();
    }
  }, [progressRequest]);

  const [showingNextStep, setShowingNextStep] = useState(false);
  const scrollRef = useRef(null);
  const nextStep = useMemo(() => {
    return topicRequest.succeeded && currentStep
      ? getNextAfterCurrentStep(currentStep, topicRequest.data)
      : null;
  }, [topicRequest, currentStep]);
  const copyStyles = useAuthContentStyles();
  const currentStepVimeoLink = useDynamicTranslation(
    (currentStep || {}).id || '0',
    TranslatableResourceTypes.STEP,
    'vimeo_link',
    (currentStep || {}).vimeo_link || ''
  );
  const currentStepTitle = useDynamicTranslation(
    (currentStep || {}).id || '0',
    TranslatableResourceTypes.STEP,
    'title',
    (currentStep || {}).title || ''
  );
  const currentSegmentBody = useDynamicTranslation(
    (currentSegment || {}).id || '0',
    TranslatableResourceTypes.SEGMENT,
    'body',
    (currentSegment || {}).body || ''
  );
  const renderDetails = () => {
    const backAction = () => props.navigateTo(`/topic_overview/${currentTopic.id}`);
    return (
      <View
        ref={mainViewRef}
        style={viewStyle}
        onLayout={() => {
          /*need onLayout for measure() to work on Android*/
        }}>
        <View style={mainContentStyle}>
          <View style={navBarStyle}>
            {screenSize > ScreenSizes.SM ? (
              <ActionLink
                bold
                leftIcon={IconNames.chevronLeft}
                title={staticTranslation('Back')}
                style={{ minWidth: 40 }}
                action={backAction}
              />
            ) : (
              <CornerButton
                iconName={IconNames.chevronLeft}
                opacity={0.3}
                action={backAction}
                iconColor={AppColors.white}
              />
            )}
          </View>

          {currentStepVimeoLink ? (
            <View style={vimeoStyle}>
              <VimeoVideo
                autoplay={props.autoplay}
                squareCorners={screenSize === ScreenSizes.SM}
                link={currentStepVimeoLink}
                startTime={isItemComplete(currentStep) ? 0 : currentStep.user_time}
                onViewingProgress={({ percent, seconds }) => {
                  percent = Math.round(100 * percent);
                  if (
                    !isTrialUser &&
                    (percent >= currentStep.user_percent_complete + 5 ||
                      (percent === 100 && currentStep.user_percent_complete !== 100))
                  ) {
                    currentStep.user_percent_complete = percent;
                    currentStep.user_time = Math.round(seconds);
                    progressRequest.send();
                  }
                  setShowingNextStep(nextStep && percent === 100);
                }}
                allowPlayback={
                  !currentResource && !currentActivity && !doingAssessment && !menuVisibleOnSM
                }
                onError={info => console.error(info)}>
                {showingNextStep ? (
                  <NextStepOverlay nextStep={nextStep} navigateWithinTopic={navigateWithinTopic} />
                ) : null}
              </VimeoVideo>
            </View>
          ) : null}

          <View style={copyStyles}>
            <Heading type={HeadingType.TWO}>{currentStepTitle}</Heading>
            <Paragraph style={styles.tdSegmentBody}>{currentSegmentBody}</Paragraph>
            <ResourcesList
              title={staticTranslation('Related Resources')}
              displayCategories
              columns={screenSize >= ScreenSizes.LG ? 2 : 1}
              resources={currentTopic.resources}
              onShowResource={resource => {
                navigateWithinTopic({ resource });
              }}
              style={styles.tdResourceList}
            />
          </View>
        </View>
      </View>
    );
  };

  let menuItemYPos = 0;
  const menuView = !canRenderDetails ? null : (
    <>
      {screenSize !== ScreenSizes.SM || !menuVisibleOnSM ? null : (
        <Animated.View style={[styles.tdNavigateContentBG, { opacity: menuBGOpacity }]} />
      )}
      {screenSize !== ScreenSizes.SM ? null : renderSMVideoNav()}
      <Animated.View style={menuContentStyle}>
        {screenSize === ScreenSizes.SM ? (
          smMenuToggleLink(false)
        ) : (
          <TopicBanner topic={currentTopic} />
        )}
        <ScrollView ref={scrollRef} contentContainerStyle={menuInnerContentStyle}>
          {currentTopic.segments.map((s, index) => {
            menuItemYPos +=
              AppStyles.normalTextHeight + (index > 0 ? MENU_SEGMENT_VERTICAL_SPACING : 0);
            const items = getSortedItemsInSegment(s, currentTopic.activities);
            return (
              <View key={s.id} style={styles.tdMenuSegmentView}>
                <SegmentHeader segment={s} />
                {items.map(item => {
                  item.menuYPos = menuItemYPos;
                  menuItemYPos += CONTENT_BOX_ROW_MIN_HEIGHT;
                  const isActivity = typeof item.activity_type !== 'undefined';
                  const locked = isTrialUser && !item.is_trial_content;
                  return (
                    <TopicMenuItem
                      key={item.id + item.title} //have to use id and title for key because steps and activities could have the same ID
                      icon={
                        locked
                          ? IconNames.lock
                          : isActivity
                            ? iconForActivity(item)
                            : IconNames.play
                      }
                      item={item}
                      minutes={item.minutes_to_complete}
                      isDraft={item.publish_status === PublishStatus.DRAFT}
                      isActive={
                        isActivity
                          ? currentActivity && item.id === currentActivity.id
                          : item.id === currentStep.id && !doingAssessment && !currentActivity
                      }
                      isComplete={isItemComplete(item)}
                      action={() => {
                        if (isActivity) {
                          navigateWithinTopic({ activity: item });
                        } else {
                          navigateWithinTopic({ step: item });
                        }
                      }}
                    />
                  );
                })}
                {s.has_user_assessment ? (
                  <TopicMenuItem
                    key="assessment"
                    title={staticTranslation('Assessment')}
                    minutes={TIME_TO_COMPLETE_ASSESSMENT}
                    isActive={doingAssessment}
                    icon={isTrialUser ? IconNames.lock : IconNames.assessment}
                    isComplete={currentTopic.user_assessment_completed}
                    action={() => {
                      navigateWithinTopic({ assessment: true });
                    }}
                  />
                ) : null}
              </View>
            );
          })}
          {topicRequest.data.nextModule && !isTrialUser ? (
            <ActionButton
              action={() => {
                props.navigateTo('/topic_overview/' + topicRequest.data.nextModule.id);
                setMenuVisibleOnSM(false);
              }}
              rightIcon={IconNames.chevronRight}
              type={ActionButtonType.SECONDARY}>
              {staticTranslation('Next Topic')}
            </ActionButton>
          ) : null}
        </ScrollView>
      </Animated.View>
    </>
  );

  const popup = (() => {
    const close = () => {
      navigateWithinTopic({ step: currentStep });
    };
    if (showSubscriptionPopup) {
      return <SubscribePopup close={close} />;
    } else if (currentActivity) {
      return (
        <ActivityPopup trackProgress={!isTrialUser} activity={currentActivity} close={close} />
      );
    } else if (currentResource) {
      return <ResourcePopup close={close} resource={currentResource} />;
    } else if (doingAssessment && currentTopic) {
      if (isTrialUser) {
        //this should only happen if the user attempts to access the assessment via direct url
        return <SubscribePopup close={close} />;
      }
      const incompleteActivityCount = currentTopic.activities.filter(
        a => !isItemComplete(a)
      ).length;
      const incompleteStepCount = currentTopic.segments.reduce((count, segment) => {
        return count + segment.steps.filter(s => !isItemComplete(s)).length;
      }, 0);
      if (incompleteActivityCount + incompleteStepCount > 0) {
        return (
          <AlertPopup
            close={close}
            title={staticTranslation('Self-Assessment')}
            body={staticTranslation(
              'You must complete all videos and activities before doing your assessment.'
            )}
          />
        );
      } else {
        return (
          <AssessmentPopup
            close={close}
            topic={currentTopic}
            onCompleted={() => (currentTopic.user_assessment_completed = true)}
          />
        );
      }
    }
    return null;
  })();

  const [shouldScrollToStep, setShouldScrollToStep] = useState(screenSize > ScreenSizes.SM);
  useEffect(() => {
    if (shouldScrollToStep && canRenderDetails) {
      scrollRef.current.scrollTo({
        x: 0,
        y: Math.max(currentStep.menuYPos - 100, 0),
        animated: true,
      });
      setShouldScrollToStep(false);
    }
  }, [shouldScrollToStep, canRenderDetails, currentStep]);
  return (
    <>
      <AuthenticatedScreen
        hideMenu={screenSize === ScreenSizes.SM}
        {...props}
        currentSection={MainSections.LIBRARY}>
        {topicRequest.isBusy ? (
          <View style={marginedContentStyle}>
            <ScreenLoadingIndicator center={screenSize === ScreenSizes.SM} />
          </View>
        ) : canRenderDetails ? (
          renderDetails()
        ) : (
          renderError()
        )}
      </AuthenticatedScreen>
      {menuView}
      {popup}
    </>
  );
};

const VIEW_MDLGXL_WIDTH = 63.5;
const MENU_SEGMENT_VERTICAL_SPACING = 40;
const styles = StyleSheet.create({
  tdViewMDLGXL: {
    width: '100%',
    minHeight: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tdNavigationBar: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tdNavigationBarSM: {
    position: 'absolute',
    top: AppStyles.mainAuthScreenPaddingSM,
    left: AppStyles.mainAuthScreenPaddingSM,
    width: '50%',
    zIndex: 100,
  },
  tdNavigationBarMDLG: {
    marginTop: AppStyles.mainAuthScreenPaddingMDLG,
    marginLeft: AppStyles.mainAuthScreenPaddingMDLG,
  },
  tdNavigationBarXL: {
    marginTop: AppStyles.mainAuthScreenPaddingXL,
    marginLeft: AppStyles.mainAuthScreenPaddingXL,
  },
  tdVimeoSM: {
    borderBottomLeftRadius: AppStyles.normalBorderRadius,
    borderBottomRightRadius: AppStyles.normalBorderRadius,
    overflow: 'hidden',
  },
  tdVimeoMDLGXL: {
    //Fix bug with Safari display of rounded corners
    borderRadius: AppStyles.normalBorderRadius,
    overflow: 'hidden',
  },
  tdVimeoMDLG: {
    paddingLeft: AppStyles.mainAuthScreenPaddingMDLG,
    paddingRight: AppStyles.mainAuthScreenPaddingMDLG,
  },
  tdVimeoXL: {
    paddingLeft: AppStyles.mainAuthScreenPaddingXL,
    paddingRight: AppStyles.mainAuthScreenPaddingXL,
  },
  tdMainContentMDLGXL: {
    width: VIEW_MDLGXL_WIDTH + '%',
    maxWidth: MAX_AUTH_SCREEN_CONTENT_WIDTH,
  },
  tdSMMenuToggle: {
    height: 70,
    alignItems: 'center',
    display: 'flex',
    marginRight: AppStyles.mainAuthScreenPaddingSM,
    marginLeft: AppStyles.mainAuthScreenPaddingSM,
  },
  tdSMMenuToggleExpanded: {
    borderBottomWidth: 2,
    borderBottomColor: AppColors.lightBackground,
  },
  tdSMMenuToggleTitleRow: {
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
  },
  tdSMMenuToggleTitle: {
    marginBottom: 0,
    marginRight: 10,
    lineHeight: 20,
    flexShrink: 1,
  },
  tdSMMenuToggleProgressBar: {},
  tdSegmentBody: {
    marginBottom: MENU_SEGMENT_VERTICAL_SPACING,
  },
  tdResourceList: {},
  tdNavigateContent: {
    backgroundColor: AppColors.white,
    ...(Platform.OS === 'web'
      ? {
          position: 'fixed',
        }
      : {
          position: 'absolute',
        }),
  },
  tdNavigateContentSM: {
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderTopRightRadius: AppStyles.largeBorderRadius,
    ...getShadowStyles({ zIndex: 100 }),
    left: 0,
    right: 0,
    bottom: 0,
  },
  tdNavigateContentMDLGXL: {
    flexGrow: 1,
    flexShrink: 1,
    right: 0,
    top: 0,
    bottom: 0,
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderBottomLeftRadius: AppStyles.largeBorderRadius,
  },
  tdNavigateContentInnerSM: {
    padding: AppStyles.mainAuthScreenPaddingSM,
  },
  tdNavigateContentInnerMDLGXL: {
    maxWidth: 500,
  },
  tdNavigateContentInnerMDLG: {
    padding: AppStyles.mainAuthScreenPaddingMDLG * 0.5,
  },
  tdNavigateContentInnerXL: {
    padding: AppStyles.mainAuthScreenPaddingXL * 0.5,
  },
  tdNavigateContentBG: {
    ...(Platform.OS === 'web'
      ? {
          position: 'fixed',
        }
      : {
          position: 'absolute',
        }),
    zIndex: 99,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.5,
    backgroundColor: '#000000',
  },
  tdMenuSegmentView: {
    marginBottom: 40,
  },
  tdMenuSegmentViewHide: {
    display: 'none',
  },
  tdMenuSegmentTitle: {
    textTransform: 'uppercase',
  },
  tdMenuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  tdMenuBadge: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.menuIconBackground,
    borderRadius: AppStyles.tinyBorderRadius,
  },
  tdMenuBadgeActive: {
    backgroundColor: AppColors.brandedBlue,
  },
  tdMenuItemTitle: {
    paddingLeft: 10,
    flexShrink: 1,
    color: AppColors.mainMenuText,
  },
  tdMenuItemTitleActive: {
    color: AppColors.brandedBlue,
  },
  tdMenuItemTime: {
    ...(Platform.OS === 'web'
      ? {
          whiteSpace: 'nowrap',
        }
      : {}),

    textAlign: 'right',
    color: AppColors.mainMenuText,
    paddingLeft: 10,
  },
  tdMenuItemNP: {
    textAlign: 'right',
    letterSpacing: -1,
    color: AppColors.orangeText,
    paddingLeft: 10,
  },
  tdNextStepView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000000',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  tdNextStepText: {
    color: AppColors.white,
  },
  tdNextStepButton: {
    borderWidth: 2,
    borderColor: AppColors.white,
    borderRadius: 25,
    height: 50,
    width: 50,
    marginBottom: 10,
  },
  tdMarginedContent: {
    maxWidth: Breakpoints.SM - AppStyles.mainAuthScreenPaddingSM,
  },
  tdMarginedContentSM: {
    margin: AppStyles.mainAuthScreenPaddingSM,
  },
  tdMarginedContentMDLG: {
    margin: AppStyles.mainAuthScreenPaddingMDLG,
  },
  tdMarginedContentXL: {
    margin: AppStyles.mainAuthScreenPaddingXL,
  },
});

export { TopicDetailScreen };
