import React from 'react';

import { OnboardingLargeImage } from './OnboardingLargeImage';

const OnboardingWelcomeLargeImage = function ({ style }) {
  const oneX = { en: require('./onboarding-welcome.jpg') };
  const twoX = { en: require('./onboarding-welcome@2x.jpg') };
  const threeX = { en: require('./onboarding-welcome@3x.jpg') };
  return <OnboardingLargeImage oneX={oneX} twoX={twoX} threeX={threeX} style={style} />;
};

export { OnboardingWelcomeLargeImage };
