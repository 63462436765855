import React from 'react';
import { StyleSheet } from 'react-native';

const QAAnswer5Image = function ({ style }) {
  const oneX = require('./VeryMuch.jpg');
  const twoX = require('./VeryMuch@2x.jpg');
  return (
    <img
      src={twoX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'contain' }}
      srcSet={`${oneX}, ${twoX} 2x`}
    />
  );
};

export { QAAnswer5Image };
