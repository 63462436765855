import React from 'react';
import { StyleSheet } from 'react-native';

const ResourceAudioButtonImage = function ({ style }) {
  const oneX = require('./resource-audio-button.jpg');
  const twoX = require('./resource-audio-button@2x.jpg');
  return <img src={twoX} alt="" style={StyleSheet.flatten(style)} srcSet={`${oneX}, ${twoX} 2x`} />;
};

export { ResourceAudioButtonImage };
