import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { addResizeObserver } from './util/polyfills';
const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

switch (document.location.pathname) {
  case '/request-account-deletion': {
    const RequestAccountDeletion =
      require('./screens/requestAccountDeletionScreen').RequestAccountDeletion;
    root.render(<RequestAccountDeletion />);
    break;
  }

  case '/health': {
    const HealthCheckScreen =
      require('./screens/healthCheckScreen').HealthCheckScreen;
    root.render(<HealthCheckScreen />);
    break;
  }
  default: {
    addResizeObserver(); //needs to run before App is instantiated with RNW imports
    const App = require('./App').default;
    root.render(<App />);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
