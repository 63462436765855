import React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';

import { Icon } from './icon';
import { Paragraph, ParagraphSizes } from './paragraph';
import { useStylesForWidth } from '../hooks/useStyleForWidth';
import { AppColors, AppStyles, IconSizes } from '../services/styles';

const MainMenuButton = function (props) {
  const { active, icon, title, action } = props;
  const style = useStylesForWidth(styles, 'mmButton', props.style);
  if (active) {
    style.push(styles.mmButtonActive);
  }
  return (
    <TouchableOpacity
      onPress={e => action(e)}
      style={style}
      role="button"
      accessibilityRole="button">
      <View style={useStylesForWidth(styles, 'mmView')}>
        <Icon
          name={icon}
          color={active ? AppColors.brandedBlue : AppColors.mainMenuText}
          size={IconSizes.normal}
        />
        <Paragraph
          size={ParagraphSizes.SMALL}
          bold
          style={useStylesForWidth(styles, 'mmText', active ? styles.mmTextActive : {})}>
          {title}
        </Paragraph>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  mmButton: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: AppStyles.normalBorderRadius,
  },
  mmButtonSM: {
    width: 50,
    height: 50,
  },
  mmButtonMD: {
    width: 60,
    height: 60,
  },
  mmButtonLGXL: {
    height: 50,
    padding: 15,
  },
  mmButtonActive: {
    backgroundColor: AppColors.withOpacity(AppColors.brandedBlue, 0.05),
  },
  mmView: {
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mmViewSMMD: {
    flexDirection: 'column',
  },
  mmViewLGXL: {
    flexDirection: 'row',
    width: '100%',
  },
  mmTextSMMD: {
    display: 'none',
  },
  mmTextLGXL: {
    paddingLeft: 10,
    color: AppColors.mainMenuText,
  },
  mmTextActive: {
    color: AppColors.brandedBlue,
  },
});

export { MainMenuButton };
