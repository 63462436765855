import React from 'react';

import { OnboardingImage } from './OnboardingImage';

const OnboardingTopicsImage = function ({ style }) {
  const twoX = {
    es: require('./es/onboarding-topics@2x.jpg'),
    fr: require('./fr/onboarding-topics@2x.jpg'),
    en: require('./onboarding-topics@2x.jpg'),
  };
  const threeX = {
    es: require('./es/onboarding-topics@3x.jpg'),
    fr: require('./fr/onboarding-topics@3x.jpg'),
    en: require('./onboarding-topics@3x.jpg'),
  };
  return <OnboardingImage twoX={twoX} threeX={threeX} style={style} />;
};

export { OnboardingTopicsImage };
