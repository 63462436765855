import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import { AppColors } from '../services/styles';
import { pressPropsForTouchable } from '../util/utils';

const ContentBoxRow = function (props) {
  const left = props.left || null;
  const right = props.right || null;
  const action = props.action;

  const v = (
    <View style={styles.cbrView}>
      {left}
      {right}
    </View>
  );

  if (action) {
    return (
      <TouchableOpacity
        role="button"
        accessibilityRole="button"
        {...pressPropsForTouchable(action)}>
        {v}
      </TouchableOpacity>
    );
  }
  return v;
};

const CONTENT_BOX_ROW_MIN_HEIGHT = 60;
const styles = StyleSheet.create({
  cbrView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: CONTENT_BOX_ROW_MIN_HEIGHT,
    borderBottomWidth: 2,
    borderBottomColor: AppColors.lightBackground,
  },
});

export { ContentBoxRow, CONTENT_BOX_ROW_MIN_HEIGHT };
