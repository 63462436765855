import React from 'react';
import { StyleSheet } from 'react-native';

const ResourceLightPurpleImage = function ({ style }) {
  const oneX = require('./resource-light-purple.jpg');
  const twoX = require('./resource-light-purple@2x.jpg');
  return (
    <img
      src={twoX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x`}
    />
  );
};

export { ResourceLightPurpleImage };
