import React, { useEffect } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import {
  ActionButton,
  ActionButtonType
} from '../shared/components/actionButton';
import { Heading } from '../shared/components/heading';
import { OrLine } from '../shared/components/orLine';
import { Paragraph } from '../shared/components/paragraph';
import { WarningText } from '../shared/components/warningText';
import { useLocale } from '../shared/hooks/useLocale';
import { useScreenTitle } from '../shared/hooks/useScreenTitle';
import { useScreenView } from '../shared/hooks/useScreenView';
import {
  USER_FIELD_EMAIL,
  USER_FIELD_EMAIL_IS_VERIFIED,
  useUserInfo
} from '../shared/services/apiService';
import { ApiService, useApiRequest } from '../shared/services/apiService';
import { staticTranslation } from '../shared/services/staticTranslations';
import { AppColors } from '../shared/services/styles';
import { AnalyticsScreenNames } from '../shared/util/enums';

const UnverifiedScreen = (props) => {
  useScreenTitle('');
  useScreenView(AnalyticsScreenNames.verifyEmail, 'UnverifiedScreen');
  const locale = useLocale();
  const apiCall = props.verify_url;
  const verificationId = props.verificationId;
  const userEmail = useUserInfo(USER_FIELD_EMAIL);
  const userIsVerified = useUserInfo(USER_FIELD_EMAIL_IS_VERIFIED);

  useEffect(() => {
    props.setHideLogo(false);
    props.setWelcomeMessageForSM(null);
    props.setAvoidKeyboard(false);
  }, [props]);

  const verifyRequest = useApiRequest(
    (cb) => {
      return ApiService.verifyEmail(apiCall, cb);
    },
    [apiCall],
    !!verificationId
  );

  const sendRequest = useApiRequest((cb) => {
    return ApiService.resendVerificationEmail(cb);
  });

  const isBusy = verifyRequest.isBusy || sendRequest.isBusy;

  const warning = () => {
    return (
      <View>
        {sendRequest.succeeded ? (
          <Paragraph style={styles.uvParagraph}>
            {staticTranslation(
              locale,
              'We have sent another email to :email with instructions for verifying your email.',
              { email: userEmail }
            )}
          </Paragraph>
        ) : (
          <Paragraph style={styles.uvParagraph}>
            {staticTranslation(
              locale,
              'An email was sent to :email with instructions for verification. Please verify your email to ensure that we can contact you when necessary.',
              { email: userEmail }
            )}
          </Paragraph>
        )}

        <Paragraph style={styles.uvParagraph}>
          {staticTranslation(
            locale,
            "If you didn't receive an email, click here to resend:"
          )}
        </Paragraph>
        <WarningText>
          {sendRequest.error
            ? `${staticTranslation(
                locale,
                'There was a problem sending this request'
              )} (${sendRequest.error})`
            : null}
        </WarningText>
        <ActionButton
          disabled={isBusy}
          showActivityIndicator={sendRequest.isBusy}
          title={staticTranslation(locale, 'Resend Verification Email')}
          action={() => sendRequest.send()}
        />
        <OrLine />
      </View>
    );
  };

  const verifying = () => {
    return verifyRequest.isBusy ? (
      <View>
        <ActivityIndicator color={AppColors.brandedBlue} />
        <Paragraph style={styles.uvParagraph}>
          {staticTranslation(locale, 'Verifying...')}
        </Paragraph>
      </View>
    ) : !verifyRequest.succeeded ? (
      <WarningText>
        {staticTranslation(
          locale,
          userIsVerified
            ? 'There was a problem processing this request, but your email already seems to be verified anyway...'
            : 'There was a problem verifying your email. This link may be expired or invalid.'
        )}
      </WarningText>
    ) : (
      <View>
        <Paragraph style={styles.uvParagraph}>
          {staticTranslation(
            locale,
            'Thank you for verifying your email. Your account setup is now fully completed.'
          )}
        </Paragraph>
      </View>
    );
  };

  return (
    <>
      <Heading>{staticTranslation(locale, 'Email Verification')}</Heading>
      {verificationId ? verifying() : warning()}
      <ActionButton
        type={ActionButtonType.SECONDARY}
        disabled={isBusy}
        action={() => props.navigateTo('/')}
        title={staticTranslation(locale, 'Return to Dashboard')}
      />
    </>
  );
};

const styles = StyleSheet.create({
  uvParagraph: {
    marginBottom: 20
  }
});

export { UnverifiedScreen };
