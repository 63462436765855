import React from 'react';
import { StyleSheet } from 'react-native';

const QAAnswer3Image = function ({ style }) {
  const oneX = require('./Somewhat.jpg');
  const twoX = require('./Somewhat@2x.jpg');
  return (
    <img
      src={twoX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'contain' }}
      srcSet={`${oneX}, ${twoX} 2x`}
    />
  );
};

export { QAAnswer3Image };
