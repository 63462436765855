import React from 'react';
import { Text, StyleSheet } from 'react-native';

import { AppColors, AppStyles } from '../services/styles';

const ParagraphSizes = {
  TINY: 'TINY',
  SMALL: 'SMALL',
  NORMAL: 'NORMAL',
};

const Paragraph = function (props) {
  const style = props.style || {};
  const size = props.size || ParagraphSizes.NORMAL;
  const bold = !!props.bold;
  return (
    <Text
      style={[
        styles.paragraph,
        styles['paragraph' + size],
        bold ? styles.paragraphBold : {},
        style,
      ]}>
      {props.children}
    </Text>
  );
};

const styles = StyleSheet.create({
  paragraph: {
    fontFamily: AppStyles.fontFamily,
    color: AppColors.darkText,
  },
  paragraphBold: {
    fontFamily: AppStyles.fontFamilyBold,
    fontWeight: AppStyles.boldWeight,
  },
  paragraphTINY: {
    fontSize: AppStyles.tinyTextSize,
    lineHeight: AppStyles.tinyTextHeight,
  },
  paragraphSMALL: {
    fontSize: AppStyles.smallTextSize,
    lineHeight: AppStyles.smallTextHeight,
  },
  paragraphNORMAL: {
    fontSize: AppStyles.normalTextSize,
    lineHeight: AppStyles.normalTextHeight,
  },
});

export { Paragraph, ParagraphSizes };
