import React from 'react';
import { StyleSheet } from 'react-native';

import { ScreenSizes, useScreenDimensions, useScreenSize } from '../../hooks/useStyleForWidth';

const TopicBannerImage = function ({ style, imageKey }) {
  const screenSize = useScreenSize();
  const screenWidth = useScreenDimensions().width;
  const size =
    screenWidth <= 420
      ? 'sm-420'
      : screenSize === ScreenSizes.LG
        ? 'lg'
        : screenSize === ScreenSizes.XL
          ? 'xl'
          : 'md';

  const oneX = require(`./topic-${imageKey}-banner-${size}.jpg`);
  const twoX = require(`./topic-${imageKey}-banner-${size}@2x.jpg`);

  return (
    <img
      src={twoX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x`}
    />
  );
};

export { TopicBannerImage };
