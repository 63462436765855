import React, { useState, useEffect } from 'react';
import { View, Platform, StyleSheet } from 'react-native';

import { AuthenticatedScreen, MAX_AUTH_SCREEN_CONTENT_WIDTH } from './authenticatedScreen';
import { ActionButton, ActionButtonType } from '../components/actionButton';
import { ActionLink } from '../components/actionLink';
import { Checkbox } from '../components/checkbox';
import { Copyright } from '../components/copyright';
import { Heading, HeadingType } from '../components/heading';
import { LanguageToggleField } from '../components/languageToggleField';
import { Paragraph, ParagraphSizes } from '../components/paragraph';
import { SubscribePopup } from '../components/popups/SubscribePopup';
import { ConfirmAccountDeletionPopup } from '../components/popups/confirmAccountDeletionPopup';
import { GiftPopup } from '../components/popups/giftPopup';
import { VerifyEmailPopup } from '../components/popups/verifyEmailPopup';
import { TextField } from '../components/textField';
import { WarningText } from '../components/warningText';
import { useAuthContentStyles } from '../hooks/useAuthContentStyles';
import { TranslatableResourceTypes, useDynamicTranslation } from '../hooks/useDynamicTranslation';
import { useLocale } from '../hooks/useLocale';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { useStylesForWidth } from '../hooks/useStyleForWidth';
import {
  useUserInfo,
  USER_FIELD_EMAIL,
  USER_FIELD_EMAIL_IS_VERIFIED,
  USER_FIELD_IS_ADMIN,
  USER_FIELD_NAME,
  USER_FIELD_INSTITUTION_NAME,
  USER_FIELD_INSTITUTION_ID,
  useApiRequest,
  ApiService,
  USER_FIELD_IS_TRIAL,
  USER_FIELD_MARKETING,
  USER_FIELD_PREFERRED_LANGUAGE,
} from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, AppStyles, IconNames } from '../services/styles';
import About from '../util/about.json';
import { AnalyticsScreenNames, MainSections, Languages } from '../util/enums';
import {
  BREATHINGROOM_WEBSITE_URL,
  CONTACT_EMAIL,
  GOOGLE_PLAY_URL,
  IOS_REVIEW_URL,
  PRIVACY_POLICY_URL,
  TERMS_OF_USE_URL,
} from '../util/links';

const ProfileSection = function (props) {
  const { title } = props;

  return (
    <View style={useStylesForWidth(styles, 'psSection')}>
      <Heading style={styles.psSectionHeading} type={HeadingType.FOUR}>
        {title}
      </Heading>
      <View style={useStylesForWidth(styles, 'psSectionContent')}>{props.children}</View>
    </View>
  );
};

const ProfileField = function (props) {
  const { label, forceFullWidth } = props;

  return (
    <View style={useStylesForWidth(styles, 'psField', forceFullWidth ? { width: '100%' } : {})}>
      {label ? (
        <Heading type={HeadingType.FOUR} style={styles.psFieldLabel}>
          {label}
        </Heading>
      ) : null}
      {props.children}
    </View>
  );
};

const ProfileScreen = function (props) {
  const locale = useLocale();
  useScreenTitle(staticTranslation(locale, 'Account'));
  useScreenView(AnalyticsScreenNames.account, 'ProfileScreen');
  const name = useUserInfo(USER_FIELD_NAME);
  const [editName, setEditName] = useState(name);
  const email = useUserInfo(USER_FIELD_EMAIL);
  const [editEmail, setEditEmail] = useState(email);
  const marketing = useUserInfo(USER_FIELD_MARKETING);
  const [editMarketing, setEditMarketing] = useState(marketing);
  const preferredLanguage = useUserInfo(USER_FIELD_PREFERRED_LANGUAGE);
  const [editPreferredLanguage, setEditPreferredLanguage] = useState(preferredLanguage);
  const [warning, setWarning] = useState('');
  const emailIsVerified = useUserInfo(USER_FIELD_EMAIL_IS_VERIFIED);
  const isAdmin = useUserInfo(USER_FIELD_IS_ADMIN);
  const institution = useUserInfo(USER_FIELD_INSTITUTION_NAME);
  const displayInstitution = useDynamicTranslation(
    useUserInfo(USER_FIELD_INSTITUTION_ID),
    TranslatableResourceTypes.INSTITUTION_CODE,
    'institution_name',
    institution
  );
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isEditingInfo, setIsEditingInfo] = useState(false);

  const updateRequest = useApiRequest(
    cb => {
      return ApiService.updateCurrentUser(
        editEmail,
        editName,
        editMarketing,
        null,
        editPreferredLanguage,
        cb
      );
    },
    [editEmail, editName, editMarketing, editPreferredLanguage]
  );

  const [refreshedProfile, setRefreshedProfile] = useState(false);
  const profileRequest = useApiRequest(
    cb => {
      return ApiService.getCurrentUser(true, cb);
    },
    null,
    !refreshedProfile
  );
  useEffect(() => {
    if (profileRequest.succeeded || profileRequest.error) {
      setRefreshedProfile(true);
    }
  }, [profileRequest]);

  const changePassword = useApiRequest(
    cb => {
      return ApiService.sendResetLinkEmail(email, preferredLanguage, cb);
    },
    [email, preferredLanguage]
  );

  const resetRequest = useApiRequest(cb => {
    return ApiService.resetProgress(cb);
  }, []);

  const requestIsBusy = updateRequest.isBusy || changePassword.isBusy || resetRequest.isBusy;

  useEffect(() => {
    if (updateRequest.succeeded) {
      setIsEditingInfo(false);
    }
  }, [updateRequest]);

  const attemptUpdate = () => {
    if (!editEmail || !editName) {
      setWarning(staticTranslation(locale, 'Oops, you need to enter your name and email!'));
    } else {
      updateRequest.send();
    }
  };

  const reset = () => {
    return (
      <ProfileSection title="For Admin Only">
        <ActionButton
          showActivityIndicator={resetRequest.isBusy}
          style={styles.psButton}
          disabled={resetRequest.isBusy}
          action={() => resetRequest.send()}
          leftIcon={resetRequest.succeeded ? IconNames.checkmark : null}
          type={ActionButtonType.SECONDARY}>
          Reset Progress
        </ActionButton>
      </ProfileSection>
    );
  };

  const popup = props.subscribe ? (
    <SubscribePopup
      hideContentMessage
      afterSubscribe={() => props.navigateTo('/dashboard')}
      close={() => props.navigateTo('/users/me')}
    />
  ) : props.gift ? (
    <GiftPopup close={() => props.navigateTo('/users/me')} />
  ) : showDeletePopup ? (
    <ConfirmAccountDeletionPopup close={() => setShowDeletePopup(false)} />
  ) : showVerifyPopup ? (
    <VerifyEmailPopup close={() => setShowVerifyPopup(false)} />
  ) : null;
  const sectionsStyle = useStylesForWidth(styles, 'psSections');

  return (
    <>
      <AuthenticatedScreen {...props} currentSection={MainSections.ACCOUNT}>
        <View style={useStylesForWidth(styles, 'psView', ...useAuthContentStyles())}>
          <Heading>{staticTranslation(locale, 'Account Information')}</Heading>
          <View style={sectionsStyle}>
            <ProfileSection title={staticTranslation(locale, 'Basic Information')}>
              <ProfileField label={staticTranslation(locale, 'Name:')}>
                {isEditingInfo ? (
                  <TextField
                    style={styles.psTextField}
                    autoFocusHardwareKeyboards
                    inputProps={{
                      disabled: requestIsBusy,
                      maxLength: 200,
                      blurOnSubmit: !!editName,
                      onSubmitEditing: () => {
                        if (editName) {
                          attemptUpdate();
                        }
                      },
                      ...(Platform.OS === 'web' ? {} : { autoCapitalize: 'words' }), //Causes bug in webkit: https://bugs.webkit.org/show_bug.cgi?id=148504
                      placeholder: staticTranslation(locale, 'Full Name'),
                      value: editName,
                      onChangeText: setEditName,
                      autoComplete: 'name',
                      autoCompleteType: 'name',
                      textContentType: 'name',
                      returnKeyType: 'go',
                      enablesReturnKeyAutomatically: true,
                    }}
                  />
                ) : (
                  <Paragraph style={styles.psFieldValue}>{name}</Paragraph>
                )}
              </ProfileField>
              <ProfileField label={staticTranslation(locale, 'Email:')}>
                {isEditingInfo ? (
                  <TextField
                    style={styles.psTextField}
                    inputProps={{
                      disabled: requestIsBusy,
                      maxLength: 100,
                      blurOnSubmit: !!editEmail,
                      onSubmitEditing: () => {
                        if (editEmail) {
                          attemptUpdate();
                        }
                      },
                      keyboardType: 'email-address',
                      placeholder: staticTranslation(locale, 'Email'),
                      autoCapitalize: 'none',
                      value: editEmail,
                      onChangeText: setEditEmail,
                      autoComplete: 'email',
                      autoCompleteType: 'email',
                      textContentType: 'emailAddress',
                      returnKeyType: 'go',
                      enablesReturnKeyAutomatically: true,
                    }}
                  />
                ) : (
                  <Paragraph style={styles.psFieldValue}>
                    {`${email}${
                      !emailIsVerified ? ` (${staticTranslation(locale, 'unverified')})` : ''
                    }`}
                  </Paragraph>
                )}
              </ProfileField>
              <ProfileField
                forceFullWidth
                label={staticTranslation(locale, 'Email Notifications:')}>
                {isEditingInfo ? (
                  <Checkbox
                    style={{ marginBottom: 20 }}
                    title={staticTranslation(
                      locale,
                      'I agree to allow BreathingRoom to send me important emails from time to time.'
                    )}
                    whiteBackground
                    checked={editMarketing}
                    onChange={() => setEditMarketing(m => !m)}
                  />
                ) : (
                  <Paragraph style={styles.psFieldValue}>
                    {marketing
                      ? staticTranslation(
                          locale,
                          'I agree to allow BreathingRoom to send me important emails from time to time.'
                        )
                      : staticTranslation(locale, "I don't want BreathingRoom to send me emails.")}
                  </Paragraph>
                )}
              </ProfileField>
              <ProfileField
                forceFullWidth
                label={staticTranslation(locale, 'Language Preference:')}>
                {isEditingInfo ? (
                  <LanguageToggleField
                    selectedLanguage={editPreferredLanguage}
                    onToggle={pref => setEditPreferredLanguage(pref)}
                  />
                ) : (
                  <Paragraph style={styles.psFieldValue}>
                    {Languages.displayLanguage(preferredLanguage)}
                  </Paragraph>
                )}
              </ProfileField>
              <ProfileField>
                <ActionButton
                  style={styles.psButton}
                  disabled={requestIsBusy}
                  showActivityIndicator={updateRequest.isBusy}
                  rightIcon={isEditingInfo ? IconNames.checkmark : IconNames.pencil}
                  title={staticTranslation(locale, isEditingInfo ? 'Save' : 'Edit')}
                  action={() => {
                    if (isEditingInfo) {
                      attemptUpdate();
                    } else {
                      setEditName(name);
                      setEditEmail(email);
                      setEditMarketing(marketing);
                      updateRequest.reset();
                      setIsEditingInfo(true);
                    }
                  }}
                />
              </ProfileField>
              <ProfileField>
                {isEditingInfo ? (
                  <ActionButton
                    style={styles.psButton}
                    type={ActionButtonType.SECONDARY}
                    disabled={requestIsBusy}
                    title={staticTranslation(locale, 'Cancel')}
                    action={() => {
                      updateRequest.reset();
                      setIsEditingInfo(false);
                    }}
                  />
                ) : !emailIsVerified ? (
                  <ActionButton
                    style={styles.psButton}
                    type={ActionButtonType.SECONDARY}
                    disabled={requestIsBusy}
                    title={staticTranslation(locale, 'Verify Email')}
                    action={() => setShowVerifyPopup(true)}
                  />
                ) : null}
              </ProfileField>
              <WarningText>
                {warning ||
                  (updateRequest.error
                    ? `${staticTranslation(locale, 'There was a problem saving these changes:')} ${
                        updateRequest.error
                      }`
                    : null)}
              </WarningText>
            </ProfileSection>

            <ProfileSection title={staticTranslation(locale, 'Password')}>
              <ProfileField label={staticTranslation(locale, 'Current Password:')}>
                <Paragraph style={styles.psFieldValue}>**********</Paragraph>
              </ProfileField>
              <ProfileField />
              <ActionButton
                disabled={requestIsBusy}
                type={ActionButtonType.SECONDARY}
                showActivityIndicator={changePassword.isBusy}
                style={styles.psButton}
                action={() => changePassword.send()}>
                {staticTranslation(locale, 'Reset Password')}
              </ActionButton>
              {changePassword.succeeded ? (
                <Paragraph style={styles.psChangePasswordResult}>
                  {staticTranslation(
                    locale,
                    "We've sent an email to :email with a link to reset/update your password.",
                    { email }
                  )}
                </Paragraph>
              ) : changePassword.error ? (
                <WarningText style={styles.psChangePasswordResult}>
                  {staticTranslation(
                    locale,
                    'There was a problem trying to initiate a password change:'
                  )}{' '}
                  {changePassword.error}
                </WarningText>
              ) : null}
            </ProfileSection>
          </View>
          <View style={sectionsStyle}>
            <ProfileSection title={staticTranslation(locale, 'Account Status')}>
              <ProfileField label={staticTranslation(locale, 'Access Level:')}>
                <Paragraph style={styles.psFieldValue}>
                  {staticTranslation(
                    locale,
                    isTrialUser ? 'Trial User' : isAdmin ? 'Admin' : 'Full Access'
                  )}
                </Paragraph>
              </ProfileField>
              {institution ? (
                <ProfileField label={staticTranslation(locale, 'Institution:')}>
                  <Paragraph style={styles.psFieldValue}>{displayInstitution}</Paragraph>
                </ProfileField>
              ) : (
                <ProfileField />
              )}
              {isTrialUser ? (
                <ProfileField>
                  <ActionButton
                    style={styles.psButton}
                    action={() => {
                      props.navigateTo('/users/me?subscribe=1');
                    }}>
                    {staticTranslation(locale, 'Unlock full access')}
                  </ActionButton>
                </ProfileField>
              ) : null}
            </ProfileSection>

            <ProfileSection title={staticTranslation(locale, 'Links')}>
              <ProfileField>
                <View style={styles.psLink}>
                  <ActionLink
                    style={styles.psActionLink}
                    disabled={requestIsBusy}
                    rightIcon={IconNames.logout}
                    action={() => props.navigateTo('/logout')}>
                    {staticTranslation(locale, 'Logout')}
                  </ActionLink>
                </View>
                <View style={styles.psLink}>
                  <ActionLink
                    style={styles.psActionLink}
                    rightIcon={IconNames.giftCard}
                    action={() => props.navigateTo('/users/me?gift=1')}>
                    {staticTranslation(locale, 'Gift Cards')}
                  </ActionLink>
                </View>
                <View style={styles.psLink}>
                  <ActionLink style={styles.psActionLink} openUrl={TERMS_OF_USE_URL}>
                    {staticTranslation(locale, 'Terms of Use')}
                  </ActionLink>
                </View>
                <View style={styles.psLink}>
                  <ActionLink style={styles.psActionLink} openUrl={PRIVACY_POLICY_URL}>
                    {staticTranslation(locale, 'Privacy Policy')}
                  </ActionLink>
                </View>
              </ProfileField>
              <ProfileField>
                <View style={styles.psLink}>
                  <ActionLink
                    style={styles.psActionLink}
                    openUrl={`mailto:${CONTACT_EMAIL}?subject=${staticTranslation(
                      locale,
                      'App Support Required'
                    )}`}>
                    {staticTranslation(locale, 'App Support')}
                  </ActionLink>
                </View>
                <View style={styles.psLink}>
                  <ActionLink style={styles.psActionLink} openUrl={BREATHINGROOM_WEBSITE_URL}>
                    {staticTranslation(locale, 'About')}
                  </ActionLink>
                </View>
                {Platform.OS === 'web' ? null : (
                  <View style={styles.psLink}>
                    <ActionLink
                      style={styles.psActionLink}
                      openUrl={Platform.OS === 'ios' ? IOS_REVIEW_URL : GOOGLE_PLAY_URL}>
                      {staticTranslation(locale, 'Write a Review')}
                    </ActionLink>
                  </View>
                )}
                {Platform.OS !== 'ios' ? null : (
                  <View style={styles.psLink}>
                    <ActionLink
                      style={styles.psActionLink}
                      disabled={requestIsBusy}
                      action={() => setShowDeletePopup(true)}>
                      {staticTranslation(locale, 'Request Account Deletion')}
                    </ActionLink>
                  </View>
                )}
              </ProfileField>
            </ProfileSection>
          </View>
          <View style={sectionsStyle}>{isAdmin ? reset() : null}</View>
          <Copyright />
          <Paragraph style={styles.psAbout} size={ParagraphSizes.SMALL}>
            {staticTranslation(locale, 'BreathingRoom Version')} {About[`${Platform.OS}Version`]} (
            {About[`${Platform.OS}Build`]})
          </Paragraph>
        </View>
      </AuthenticatedScreen>
      {popup}
    </>
  );
};

const styles = StyleSheet.create({
  psView: {
    width: '100%',
  },
  psViewLGXL: {
    maxWidth: MAX_AUTH_SCREEN_CONTENT_WIDTH,
  },
  psSectionsLGXL: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  psSection: {
    marginBottom: 50,
    paddingBottom: 50,
    borderBottomColor: AppColors.menuIconBackground,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  psSectionLGXL: {
    width: '47%',
  },
  psSectionHeading: {
    color: AppColors.darkText,
  },
  psSectionContentMDLGXL: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  psFieldMDLGXL: {
    width: '50%',
    paddingRight: 10,
  },
  psFieldLabel: {
    marginBottom: 0,
    marginTop: 20,
  },
  psFieldValue: {
    marginBottom: 20,
  },
  psTextField: {
    backgroundColor: AppColors.white,
    width: '90%',
    height: 50,
    borderRadius: AppStyles.smallBorderRadius,
  },
  psLink: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  psActionLink: {
    height: 'auto',
    paddingTop: 12,
    paddingBottom: 12,
    maxWidth: '100%',
  },
  psChangePasswordResult: {
    marginTop: 20,
  },
  psButton: {
    marginTop: 20,
    minWidth: 160,
    alignSelf: 'flex-start',
  },
  psAbout: {
    marginTop: 10,
  },
});

export { ProfileScreen };
