import React from 'react';

import { OnboardingImage } from './OnboardingImage';

const OnboardingDashboardImage = function ({ style }) {
  const twoX = {
    es: require('./es/onboarding-dashboard@2x.jpg'),
    fr: require('./fr/onboarding-dashboard@2x.jpg'),
    en: require('./onboarding-dashboard@2x.jpg'),
  };
  const threeX = {
    es: require('./es/onboarding-dashboard@3x.jpg'),
    fr: require('./fr/onboarding-dashboard@3x.jpg'),
    en: require('./onboarding-dashboard@3x.jpg'),
  };
  return <OnboardingImage twoX={twoX} threeX={threeX} style={style} />;
};

export { OnboardingDashboardImage };
